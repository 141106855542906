import { buildAuthHeaders } from 'utils/buildAuthHeaders';
import { handleResponse } from './helpers/helpers';

export const getLinkedUsers = async aid => {
  const accountId = aid || localStorage.getItem('aid');
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/accounts/${accountId}/users?start=0&limit=100`,
    {
      method: 'GET',
      headers: myHeaders,
    }
  );
  return await handleResponse(res);
};

export const postLinkedUser = async (data, aid) => {
  const accountId = aid || localStorage.getItem('aid');
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/accounts/${accountId}/users`,
    {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
    }
  );
  return await handleResponse(res);
};

export const updateLinkedUser = async (id, data, aid) => {
  const accountId = aid || localStorage.getItem('aid');
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/accounts/${accountId}/users/${id}`,
    {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(data),
    }
  );
  return await handleResponse(res);
};

export const deleteLinkedUser = async (uid, aid) => {
  const accountId = aid || localStorage.getItem('aid');
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/accounts/${accountId}/users/${uid}`,
    {
      method: 'DELETE',
      headers: myHeaders,
    }
  );
  return await handleResponse(res);
};

export const getAccountRolesAndAssigments = async aid => {
  const accountId = aid || localStorage.getItem('aid');
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/accounts/${accountId}/roles`,
    {
      method: 'GET',
      headers: myHeaders,
    }
  );
  return await handleResponse(res);
};

export const updateAccountOwner = async (newOwnerId, oldOwnerId, aid = '') => {
  const accountId = aid || localStorage.getItem('aid');
  let myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API}/api/admin/v2/accounts/${accountId}/transferowner`,
    {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({ newOwnerId, oldOwnerId }),
    }
  );
  return await handleResponse(res);
};
